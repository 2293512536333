<h2 class='branch-name' [class.compact]='compact'>
    @if (card.branch_operating_unit) {
        <span class='primary'>{{card.branch_operating_unit}}</span>
    } @else if (card.organization_name_parts) {
        <span class='primary'>{{card.organization_name_parts.primary}}</span>
        @if (card.organization_name_parts.secondary) {
            <span class='secondary'> - {{card.organization_name_parts.secondary}}</span>
        }
    } @else {
        @if (card.organization_short_name) {
            <span class='primary'>{{card.organization_short_name}}</span>
        } @else {
            <span class='primary'>{{card.organization_name}}</span>
        }
    }
</h2>
