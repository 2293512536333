<div class='disclaimer' [class.homepage]='homepage'>
    <div class='bigger'>
        <p>
            <strong>כל שירות</strong>&nbsp;ממפה מגוון של שירותים ומענים חברתיים שהמדינה, ארגונים וגופים  שונים מעמידים לרשותינו, האזרחים. בעזרת סינון, חיפוש ושיטוט במפה ניתן לגלות בדיוק מי יוכל לעזור לך.
        </p>
        <p>
            המערכת כרגע בגירסת בטא - חלק מהמידע יכול להיות חסר או לא מדויק.
        </p>
    </div>
    <div class='link'>
        <span>אתר כל שירות מופעל ע״י</span>
        &nbsp;<a class='style-kz' href='https://www.kolzchut.org.il/he/כל-זכות:אודות_המיזם' target='_blank' aria-label='מעבר לאתר של כל זכות בטאב חדש'>כל זכות</a>
        <br/>
        <span>בסיוע</span>
        &nbsp;<a class='style-il' href='https://www.gov.il/he/departments/ministry_of_justice/govil-landing-page' target='_blank' aria-label='מעבר לאתר של משרד המשפטים בטאב חדש'>משרד המשפטים</a>
        &nbsp;ו<a class='style-il' href='https://www.gov.il/he/departments/national-digital-agency/govil-landing-page' target='_blank' aria-label='מעבר לאתר של מערך הדיגיטל הלאומי בטאב חדש'>מערך הדיגיטל הלאומי</a>. אין בסיוע הממשלתי משום נטילת אחריות לפעילות האתר או תכניו. האתר מיועד לכלל המגדרים.
    </div>
    <p class='data-disclaimer'>
        כל זכות בע"מ (חל"צ), אינה אחראית לתוכן המוצג באתר. המידע באתר הוא כללי, ברובו מוזן בהדרגה ממקורות שונים בצורה אוטומטית, ואינו בהכרח מלא, מדויק, עדכני וכיו"ב. השימוש באתר הוא באחריות המשתמשים בלבד. למידע נוסף ראו הסבר על 
        <a href='https://www.kolzchut.org.il/he/%D7%9B%D7%9C-%D7%96%D7%9B%D7%95%D7%AA:%D7%9B%D7%9C-%D7%A9%D7%99%D7%A8%D7%95%D7%AA_-_%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA_%D7%94%D7%A9%D7%99%D7%9E%D7%95%D7%A9'
           aria-label='מדיניות האתר - נפתח בחלון חדש'>מדיניות האתר</a>.
    </p>
    <p class='data-disclaimer'>פיתוח, עיצוב ושותפות בניהול מוצר: אדם קריב, מושון זר-אביב ומור רובינשטיין, <a href='https://whiletrue.industries' target='_blank'>While True Industries</a>.</p>
    <app-desktop-menu-links [footer]='true'></app-desktop-menu-links>
</div>