<app-menu-popup title='שותפים'>
    <ng-container class='content'>
        <p>
            אתר כל שירות נבנה ע"י 
            <a href='https://www.kolzchut.org.il/he/כל-זכות:אודות_המיזם'>
                כל זכות בע"מ (חל"צ)
            </a>
             כחלק ממיזם הזכויות הממשלתי בהובלת משרד המשפטים ומערך הדיגיטל הלאומי. בנוסף יש מספר גופים שמלווים ומסייעים להקמה של כל שירות:
        </p>
        <ul>
            <li>
                אגף ממשל וחברה במשרד ראש הממשלה
            </li>
            <li>
                יחידת ממשל זמין ברשות התקשוב
            </li>
            <li>
                רשות התאגידים ואתר גיידסטאר
            </li>
            <li>
                משרד הרווחה והביטחון החברתי
            </li>
            <li>
                משרד הבריאות
            </li>
            <li>
                משרד החינוך
            </li>
        </ul>
        <p>
            בכל מקרה גם כל זכות וגם הגופים השותפים אינם אחראים על המידע המוצג באתר
        </p>
    </ng-container>
</app-menu-popup>