<div class='main-content'>
    <h1 class='name'>{{card.service_name}}</h1>
    <div class='description' *ngIf='card.service_description'>{{card.service_description}}</div>
    <div class='implements' *ngIf='card.service_implements'>
        <span>השירות ניתן על ידי {{card.organization_name}} מטעם {{implementingOffice()}}.
            <br/><a [href]='implementingLink()' target='_blank'>למידע נוסף על השירות</a>
        </span>
    </div>
    <div class='responses'>
        <h2>מהות השירות:</h2>
        <div class='list'>
            <app-response-linky *ngFor='let response of card.responses' [response]='response'></app-response-linky>
        </div>
    </div>
    <div class='situations' *ngIf='card.situations && card.situations.length'>
        <h2>השירות מתאים עבור:</h2>
        <div class='list'>
            <app-situation *ngFor='let situation of card.situations' [situation]='situation' [link]='true'></app-situation>
        </div>
    </div>
    <div class='contact'>
        <h2>פרטי התקשרות:</h2>
        <div class='actions'>
            <app-card-action-phone [card]='card' [fullwidth]='true' (copied)='snack($event)'></app-card-action-phone>
            <app-card-action-email [card]='card' [fullwidth]='true' (copied)='snack($event)'></app-card-action-email>
            <app-card-action-url [card]='card' [fullwidth]='true' (copied)='snack($event)'></app-card-action-url>
            <app-card-action-nav [card]='card' [fullwidth]='true' (copied)='snack($event)'></app-card-action-nav>
        </div>
    </div>
    <div class='terms' *ngIf='card.service_details || card.service_payment_details'>
        <h2>תנאים לקבלת השירות:</h2>
        <div [innerHTML]='format(card.service_details)' *ngIf='card.service_details'></div>
        <div [innerHTML]='format(card.service_payment_details)' *ngIf='card.service_payment_details'></div>
    </div>
    <div class='org'>
        <h2>השירות ניתן ע״י:</h2>
        <div class='org-container' [class.open]='orgOpen' aria-label='הרחבת פרטי הארגון' clickOnReturn (activated)='orgOpen = !orgOpen && hasOrgActions()'>
            @if (card.organization_name_parts) {
                <div class='org-name'>                   
                    @if (card.organization_urls?.[0]?.href) {
                        <a [href]='card.organization_urls[0].href' target='_blank'>{{card.organization_name_parts.primary}}</a>
                    } @else {
                        <span>{{card.organization_name_parts.primary}}</span>
                    }
                    @if (card.organization_name_parts.secondary) {
                        <span> - {{card.organization_name_parts.secondary}}</span>
                    }
                </div>    
            }
            <span class='opener' [class.open]='orgOpen' *ngIf='hasOrgActions()'></span>
            @if (!card.organization_name_parts) {
                <div class='org-name'>
                    @if (card.organization_urls?.[0]?.href) {
                        <a [href]='card.organization_urls[0].href' target='_blank'>{{card.organization_name}}</a>
                    } @else {
                        <span>{{card.organization_name}}</span>
                    }
                </div>    
            }
            <div class='actions' *ngIf='orgOpen'>
                <app-card-action-phone [card]='card' [org]='true' [compact]='true'></app-card-action-phone>
                <app-card-action-email [card]='card' [org]='true' [compact]='true'></app-card-action-email>
            </div>
            <div class='org-branches' *ngIf='card.organization_branch_count > 1'>
                <a (activated)='showAllBranches($event)' [href]='"/s/" + card.organization_id + "?from=card-show-branches"' clickOnReturn aria-label="הצגת כל סניפי הארגון">{{card.organization_branch_count}} סניפים בארץ ←</a>
            </div>
        </div>
    </div>
    <div class='non-official-warning' *ngIf='nonOfficial'>
        <span>
            <strong>שימו לב:</strong><br/>
            <span>השירות אינו מופעל ע״י רשות ציבורית או עמותה רשומה</span>
        </span>
    </div>
    <div class='data-sources'>
        <ng-container *ngFor='let data_source of dataSources'>
            <span class='data-source' [innerHtml]='data_source'></span>
        </ng-container>
    </div>
    <div class='error-report'>
        <a [href]='suggestChangesForm' target='_blank'>
            <div class='icon'></div>
            <span *ngIf='!nonOfficial'>מצאתי טעות בנתונים</span>
            <span *ngIf='nonOfficial'>מצאתי בעיה בשירות או טעות בנתונים</span>
        </a>
    </div>
</div>
<app-card-branch-services [card]='card'></app-card-branch-services>
<app-disclaimer-footer></app-disclaimer-footer>
<div class='snack' [class.visible]='snackVisible'>
    {{ snackMessage }}
</div>
<div class='quick-actions' [class.visible]='quickActionsVisible'>
    <app-quick-actions [card]='card'></app-quick-actions>
</div>    
