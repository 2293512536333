<div class='address' [class.compact]='compact'>
    <ng-container *ngIf='!card.national_service'>
        <ng-container *ngIf='!card.address_parts'>
            <span class='primary'>{{card.branch_address}}</span>
        </ng-container>
        <ng-container *ngIf='card.address_parts'>
            <span class='primary'>{{card.address_parts.primary}}</span>
            <span class='secondary' *ngIf='card.address_parts.secondary'><ng-container *ngIf='card.branch_location_accurate'> -</ng-container> {{card.address_parts.secondary}}</span>
        </ng-container>
    </ng-container>
    <ng-container *ngIf='card.national_service'>
        <span class='national-service'>השירות ניתן בכל הארץ</span>
    </ng-container>
</div>
