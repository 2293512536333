<div class='page-container' [class]='"stage-" + stage'>
    <app-homepage *ngIf='stage === "homepage"'></app-homepage>
    <app-search [query]='searchSvc.searchQ | async' *ngIf='stage === "search"'></app-search>
    <app-searchbox-header *ngIf='layout.desktop() && (stage === "search-results" || stage === "card" || stage.indexOf("about:") === 0)'
        class='layout-desktop'
        [query]='query'
        [searchParams]='searchParams'
    ></app-searchbox-header>
    <div class='main-content' *ngIf='stage === "search-results" || stage === "card" || stage === "point"'>
        <div class='search-results'>
            <app-searchbox-header *ngIf='layout.mobile() &&  stage === "search-results"' class='layout-mobile'
                    [query]='query'
                    [searchParams]='searchParams'
            >
                <app-search-filters-bar [filtersState]='filtersState'></app-search-filters-bar>
            </app-searchbox-header>
            <app-results-drawer
                [state]='stage === "point" ? DrawerState.Hidden : (searchState.onlyNational() ? DrawerState.Most : drawerState)'
                [nationalCount]='nationalCount'
                [searchState]='searchState'
                [areaSearchState]='areaSearchState'
                *ngIf='searchParams'
                [class.visible]='stage === "point" || stage === "search-results"'
                [scrollAll]='false'
                (handle)='handleDrawer($event)'
                (size)='drawerSize = $event'
            >
                <div class='content search-filters' ngProjectAs='content-header' 
                    [class.visible]='stage === "search-results" || (stage === "point" && !card)'
                    [class.filters]='filtersVisible'
                >
                    <app-search-filters-bar class='layout-desktop' [filtersState]='filtersState' *ngIf='layout.desktop()'></app-search-filters-bar>
                    <app-search-filters 
                        *ngIf='filtersVisible'
                        [searchParams]='searchParams'
                        [areaSearchState]='areaSearchState'
                        [filtersState]='filtersState'
                        [searchState]='searchState'
                        #searchFilters
                    ></app-search-filters>
                </div>
                <div class='content main' ngProjectAs='content' 
                    [class.visible]='stage === "search-results" && !filtersVisible'
                    [class.filters]='filtersVisible'
                >
                    <app-search-results
                        [class.hidden]='filtersVisible === true' 
                        [class.shown]='filtersVisible === false' 
                        [searchParams]='searchParams'
                        [active]="stage === 'search-results'"
                        [didYouMean]='didYouMean'
                        [searchState]='searchState'
                        (zoomout)='zoomOutMap($event)'
                        (visibleCount)='visibleCount = $event'
                        (nationalCount)='nationalCount = $event'
                        (hoverCard)='hoverCard($event)'
                    ></app-search-results>
                    <app-disclaimer-footer [homepage]='true'></app-disclaimer-footer>
                </div>
        <!-- (handle)='handleEvent($event)' (height)='updateDrawerHeight($event)' (scrollTop)='drawerScrolled = !$event'
                            [class.away]='disclaimerVisible || infoPage'> -->
            </app-results-drawer>
        </div>
        <app-branch-container
            *ngIf='stage === "point"' 
            [cardId]='card' 
            [pointId]='point' 
            [searchParams]='searchParams'
            (size)='branchSize = $event'
            (markerProps)='markerProps = $event'
        >
        </app-branch-container>
        <app-card-container
            *ngIf='stage === "card"' 
            [cardId]='card' 
            [searchParams]='searchParams'
            (center)='centerMap($event)'
            (size)='branchSize = $event'
            (zoomout)='zoomOutMap($event, 3000)'
        >
        </app-card-container>
        <app-area-search-selector-results [state]='areaSearchState' *ngIf='layout.mobile()' class='layout-mobile'></app-area-search-selector-results>
        <app-landing-page-overlay *ngIf='(stage === "search-results" || stage === "card")'
            [style.display]='showLandingPageOverlay ? "flex" : "none"'
            [landingPage]='analytics.currentPageLanding'
            [searchParams]='searchParams' 
            [cardId]='card'
            [visibleCount]='visibleCount'
            (open)='showLandingPageOverlay = $event'
        ></app-landing-page-overlay>
    </div>
    <app-menu></app-menu>
    
    <app-menu-popup-contact *ngIf='stage === "about:contact"'></app-menu-popup-contact>
    <app-menu-popup-partners *ngIf='stage === "about:partners"'></app-menu-popup-partners>
    <app-menu-popup-about *ngIf='stage === "about:about"'></app-menu-popup-about>
    <app-menu-popup-missing *ngIf='stage === "about:missing"'></app-menu-popup-missing>
    <app-menu-popup-index *ngIf='stage === "about:index"'></app-menu-popup-index>
</div>
<app-map *ngIf='stage !== "homepage"' 
        [searchParams]='searchParams' 
        [cardId]='card'
        [pointId]='point'
        [markerProps]='markerProps'
        (map)='map = $event'
        (mapBounds)='bounds = $event'
        (focusOn)='focusOn.next($event)'
        aria-hidden='true'
        tabindex="-1"
></app-map>
<span aria-live='assertive' class='a11y'>{{a11y.title | async}}</span>
<div class='survey' id='survey' #survey [class.visible]='surveyVisible'></div>