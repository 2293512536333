<a class='map-window' 
        [class.point-mode]='mode === MapWindowMode.Point'
        [class.inaccurate]='mode === MapWindowMode.Inaccurate'
        [class.national-service]='mode === MapWindowMode.NationalService'
        [class.passthrough]='passthrough()'
        [routerLink]='link'
        [attr.role]='link ? "button" : "presentation"'
        [attr.aria-label]='link ? "צפייה בכל השירותים שבסניף זה" : null'
        [attr.aria-hidden]='link ? "false" : "true"'
        queryParamsHandling="preserve"
    >
    <div class='message-blur' *ngIf='mode === MapWindowMode.Inaccurate || mode === MapWindowMode.NationalService'>&nbsp;</div>
    <div class='message' *ngIf='mode === MapWindowMode.Inaccurate'>*שימו לב: הכתובת שבידינו אינה מדויקת</div>
    @if (mode === MapWindowMode.NationalService) {
        <app-area-search-national-services-notification [single]='true'></app-area-search-national-services-notification>
    }
</a>