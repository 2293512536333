<div class='wrapper'>
    <a class='logo layout-desktop' [routerLink]='["/"]' aria-label='חזרה לעמוד הבית'></a>
    <div class='searchbox'>
        <app-menu-icon *ngIf='layout.mobile()'></app-menu-icon>
        <h1 class='clickable' (activated)='search(searchParams?.original_query || "")' [title]='searchParams?.original_query ? searchParams.original_query : ""'
           aria-label='ביצוע חיפוש חדש באתר כל שירות'
           interactionEvent='regular-searchbar' [interactionEventWhere]='location.path(false)' [searchParams]='searchParams'
           clickOnReturn
            >
            <div class='search-params' *ngIf='placeholder'>
                <span class='placeholder'>{{placeholder}}</span>
            </div>
            <div class='search-params' *ngIf='searchParams'>
                <ng-container *ngIf='searchParams.query'>
                    <h1 class='query'>{{searchParams.query}}</h1>
                </ng-container>
                <ng-container *ngIf='!searchParams.query'>
                    <span class='query' *ngIf='responseDisplay'>{{responseDisplay}} </span>
                    <span class='query dim' *ngIf='!responseDisplay'>כל שירות </span>
                    <div class='extra-values'>
                        <ng-container *ngIf='situationDisplay'>
                            <span class='text'>עבור {{situationDisplay}}</span>
                        </ng-container>
                        <ng-container *ngIf='!situationDisplay'>
                            <span class='text soft'>עבור כולם</span>
                        </ng-container>
                        <ng-container *ngIf='orgDisplay'>
                            <span class='separator'> </span>
                            <span class='text'>של {{orgDisplay}}</span>
                        </ng-container>
                    </div>
                </ng-container>    
            </div>
            <div class='search-icon'></div>    
        </h1>
    </div>
    <app-desktop-menu-links></app-desktop-menu-links>
</div>
<ng-content></ng-content>