@for (card of cards; track card.card_id; let i = $index) {
    <a class='card' 
        [class.last-non-hidden]='i === cards.length - 1 && hidden_'
        [routerLink]='routerLink(card)' queryParamsHandling='merge'
        [queryParams]='{from: "point-view"}'
        [attr.data-card]='card | json' [style.zIndex]='200 - i'
>
    <app-result-card [card]='card' [compact]='true'></app-result-card>
</a>
}
@if (hidden_) {
    <a class='hidden-trigger' (click)='triggerClicked()'>
        <div class='bg-cards'>
            @for (_ of hiddenCards.slice(0, 4); track _.card_id) {
                <div class='bg-card'></div>
            }
        </div>
        <span class='number'>+ {{hiddenCards.length}}</span>
    </a>    
}
@if (triggerClicked_) {
    @for (card of hiddenCards; track card.card_id; let i = $index) {
        <a class='card'
            [routerLink]='routerLink(card)'
            [queryParams]='{from: "point-view"}'
            [class.hidden]='hidden_'  queryParamsHandling='merge'
            [attr.data-card]='card | json' [style.zIndex]='100 - i'
    >
        <app-result-card [card]='card' [compact]='true'></app-result-card>
    </a>
    }    
}
