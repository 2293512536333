<div class='backdrop' [class.visible]='visible' [class.active]='active' (activated)='closeMe(null)' clickOnReturn role="presentation">

</div>
<div class='menu'
    [class.enter]='active'
    [class.visible]='visible'
    role="dialog"
    aria-label="תפריט ראשי"
    [attr.aria-modal]='active ? "true" : "false"'
>
    <div class='container'>
        <a class='logo' [routerLink]='["/"]' #menu aria-label="מעבר לדף הבית"></a>
        <a class='menu-item' [routerLink]='["/"]'>דף הבית</a>
        <a class='menu-item' [routerLink]='["/about", "kolsherut"]'>אודות</a>
        <a class='menu-item' [routerLink]='["/about", "missing"]'>הוסיפו שירות חסר</a>
        <a class='menu-item' [routerLink]='["/about", "partners"]'>שותפים</a>
        <a class='menu-item' [routerLink]='["/about", "contact"]'>צרו קשר</a>
    </div>
    <div class='closer' (activated)='closeMe(null)' clickOnReturn tabindex="0" role="button" aria-label="סגירת התפריט הראשי"></div>
</div>
