<div class='map-window layout-desktop' [class.national-service]='nationalService' #mapWindow>
    <app-map-window [mode]='inaccurate ? MWM.Inaccurate : (nationalService ? MWM.NationalService : MWM.Card)'></app-map-window>
</div>
<div class='scrolled' #scrolled>
    <div class='map-window layout-mobile' [class.national-service]='nationalService' #mapWindow>
        <app-map-window [mode]='inaccurate ? MWM.Inaccurate : (nationalService ? MWM.NationalService : MWM.Card)' [link]='branchLink'></app-map-window>
    </div>
    <div class='content'
        #content
    >
        <div class='branch'>
            <app-branch-header [card]='card' [link]='branchLink' [cardId]='cardId' [landingPage]='landingPage' [compact]='false'></app-branch-header>
            <app-card *ngIf='card' [card]='card' (zoomout)='zoomout.emit($event)'></app-card>
        </div>
    </div>
    <div class='controls layout-mobile'>
        <a class='back' (activated)='navigateBack()' clickOnReturn *ngIf='!landingPage' aria-label="חזרה לעמוד הקודם"></a>
        <div class='menu-icon' *ngIf='landingPage'><app-menu-icon colorScheme='dark'></app-menu-icon></div>
        <a class='search' (activated)='searchSvc.search(searchParams && searchParams.original_query || "")' aria-label="ביצוע חיפוש חדש"
            interactionEvent='mobile-search-button' [interactionEventWhere]='location.path(false)' [searchParams]='searchParams' clickOnReturn
        ></a>
    </div>    
</div>
