<ng-container *ngIf='cards && cards.length'>
    <div class='scrollable'>
        @for (card of cards; track card.card_id) {
            <a [interactionEvent]='"popup-card-click"' [interactionEventWhere]='"map-popup-stable"'
               [routerLink]='cardRouter.route(card)' [queryParamsHandling]='"merge"' [queryParams]='{from: "map-popup-single-branch"}'>
                <app-result-card [card]='card' [stacked]='true' [smaller]='true' [bold]='true'></app-result-card>
            </a>
        }
        <div class='footer' *ngIf='cards.length < props.service_count'>
            +{{props.service_count - cards.length}} שירותים נוספים
        </div>
    </div>
    <div class='maxi header'>
        <app-branch-details [card]='cards[0]'></app-branch-details>
    </div>
</ng-container>
<ng-container *ngIf='branches && branches.length'>
    <div class='scrollable'>
        <ng-container *ngFor='let branch of branches'>
            <div class='mini header'>
                <app-branch-details-org [card]='branch.cards[0]'></app-branch-details-org>
            </div>
            @for (card of branch.cards; track card.card_id) {
                <a [interactionEvent]='"popup-card-click"' [interactionEventWhere]='"map-popup-stable"'
                   [routerLink]='cardRouter.route(card)' [queryParamsHandling]='"merge"' [queryParams]='{from: "map-popup-multi-branch"}'
                >
                    <app-result-card [card]='card' [stacked]='true' [smaller]='true' [bold]='true'></app-result-card>
                </a> 
            }
            <div class='footer' *ngIf='branch.hidden.length'>
                +{{branch.hidden.length}} שירותים נוספים
            </div>                
        </ng-container>
        <div class='maxi header'>
            <app-branch-details-address [card]='branches[0].cards[0]'></app-branch-details-address>
        </div>
    </div>
</ng-container>
<ng-container *ngIf='!(cards?.length) && !(branches?.length)'>
    <div class='placeholder loading'></div>
    <div class='placeholder loading'></div>
</ng-container>    
