<div class='dialog' role='dialog' aria-modal='true' (click)='$event.stopPropagation()'>
    <div class='closer' (activated)='closeMe()' clickOnReturn role="button" tabindex="0" aria-label="סגירת תיבת הטקסט וחזרה לעמוד הקודם"></div>
    <div class='container'>
        <div class='header' *ngIf='title || subtitle'>
            <h1 [innerHTML]="title" *ngIf='title'></h1>
            <h2 [innerHTML]="subtitle" *ngIf='subtitle'></h2>    
        </div>
        <ng-content select=".content"></ng-content>
    </div>
    <ng-content select=".bottom"></ng-content>
</div>
