<div class='accordion' [class.open]='open' [style.height.px]='height()'>
    <div class='accordion-inner' #inner>
        <div class='section-title' (activated)='select()' clickOnReturn>
            <div class='title'>{{title}}</div>
            <div class='icon'></div>
        </div>
        <div class='section-text'>
            <ng-content></ng-content>
        </div>
    </div>
</div>
