<a class='response'
    [style.background-color]='bgColor'
    [style.border-color]='borderColor'
    (activated)='doSearch($event)'
    [href]='"/s/" + responseQuery + "?from=tag-response"'
    [class.small]='small'
    [attr.aria-label]='response.name + " - לחצו לחיפוש באתר"'
    (mouseover)='hover = true' (mouseleave)='hover = false'
    clickOnReturn
>
    <span class='point'>
        <span
            [style.background]='pointBgColor'
            [style.border-color]='pointBorderColor'
        ></span>
    </span>
    <span class='name'
        [class.small]='small'
        [style.font-weight]='fontWeight'
        [style.color]='textColor'
    >{{response.name}}</span>
    <span class='link'
        [style.background-color]='linkBgColor'
    ><span [style.color]='linkColor'>←</span></span>
</a>