<div class='situation' [class.small]='small' *ngIf='!selected && !link'
    [class.small]='smaller'
>
    <span class='text' [innerHTML]='situation.name'
    ></span>
</div>
<a class='situation linkable' [class.small]='small' *ngIf='!selected && link'
    (activated)='doSearch($event)'
    [href]='"/s/" + querySituation + "?from=tag-situation"'
    [class.small]='smaller'
    [attr.aria-label]='situation.name + " - לחצו לחיפוש באתר"'
    clickOnReturn
>
    <span class='text' [innerHTML]='situation.name'></span>
    <span class='link'><span>←</span></span>
</a>
<div class='situation' *ngIf='selected'
    [class.selected]='true'
    [attr.aria-label]='situation.name + " - לחצו להסרת הסינון"'
    (click)='onClick()'
    role='button'
    tabindex="0"
>
    <span class='text' [innerHTML]='situation.name'
    ></span>
    <span class='icon close'></span>
</div>
