<div class='situations-container'>
    <div class='situations'>
        <div class='section' *ngIf='filtersState.responseItems && filtersState.responseItems.length'>
            <div class='section-header'>
                <!-- <div class='title' *ngIf='!searchParams.response'>סוגי שירות:</div>
                <div class='title' *ngIf='!!searchParams.response'>סוגי שירות נוספים:</div> -->
                <div class='title'>סוגי שירות:</div>
                <a class='clear' (activated)='filtersState.currentSearchParams.filter_responses = []; filtersState.pushSearchParams()' clickOnReturn role='button' tabindex="0" aria-label="ניקוי המסננים לסוגי השירותים">ניקוי</a>
            </div>
            <app-response-selection-widget [responses]='filtersState.responseItems' [selectedResponses]='(this.filtersState.currentSearchParams && this.filtersState.currentSearchParams.filter_responses) || []' (toggle)='filtersState.toggleResponse($event)'></app-response-selection-widget>
        </div>
        <app-search-filters-situation-section title='קהלי יעד' [situations]='filtersState.audiences' [filtersState]='filtersState' [field]='"filter_situations"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='מצבים רפואיים ומוגבלויות' [situations]='filtersState.health_issues' [filtersState]='filtersState' [field]='"filter_health"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='מוכרות ממשלתית' [situations]='filtersState.others.benefit_holders' [filtersState]='filtersState' [field]='"filter_benefit_holders"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='מצב תעסוקתי' [situations]='filtersState.others.employment' [filtersState]='filtersState' [field]='"filter_employment"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='אירועי חיים' [situations]='filtersState.others.life_events' [filtersState]='filtersState' [field]='"filter_life_events"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='דחיפות' [situations]='filtersState.others.urgency' [filtersState]='filtersState' [field]='"filter_urgency"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='קהילה' [situations]='filtersState.others.community' [filtersState]='filtersState' [field]='"filter_community"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='תפקיד' [situations]='filtersState.others.role' [filtersState]='filtersState' [field]='"filter_role"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='קבוצות גיל' [situations]='filtersState.age_groups' [filtersState]='filtersState' [field]='"filter_age_groups"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='מיועד עבור' [situations]='filtersState.others.gender' [filtersState]='filtersState' [field]='"filter_gender"'></app-search-filters-situation-section>
        <app-search-filters-situation-section title='לדוברי שפות (שאינן עברית)' [situations]='filtersState.languages' [filtersState]='filtersState' [field]='"filter_languages"'></app-search-filters-situation-section>
    </div>    
</div>
<div class='submit'>
    <div class='inner'>
        <a class='cancel' (activated)='filtersState.cancel()' *ngIf='filtersState.currentSearchParams?.searchHash !== filtersState.restoreSearchParams?.searchHash' clickOnReturn role='button' tabindex="0" aria-label="סגירת אפשרויות הסינון ללא שמירה">
            ביטול
        </a>
        <button class='main' (click)='filtersState.closeWithParams()'>
            @if (searchState.nationalLoading()) {
                <span class='filters-loading'></span>
            } @else {
                <span aria-live='polite'>
                    @if (searchState.nationWideCount() > 1) {
                        <strong>{{searchState.nationWideCount().toLocaleString()}} תוצאות</strong>
                    } @else if (searchState.nationWideCount() === 1) {
                        <strong>תוצאה אחת</strong>
                    } @else {
                        <strong>אין תוצאות</strong>
                    }
                </span>
                @if (searchState.mapLoading()) {
                    <span class='bounded'>&nbsp;(</span><span class='filters-loading'></span><span>)</span>
                } @else {
                    @if (searchState.mapCount() >= 0 && searchState.mapCount() !== searchState.nationWideCount() && (!!areaSearchState.area_ || !areaSearchState.nationWide_)) {
                        <span class='bounded'>
                            &nbsp;
                            @if (searchState.mapCount() > 1) {
                                <span>({{searchState.mapCount().toLocaleString()}} באיזור המפה)</span>
                            } @else if (searchState.mapCount() === 1) {
                                <span>(אחת באיזור המפה)</span>
                            } @else {
                                <span>(אין באיזור המפה)</span>
                            }
                        </span>    
                    }
                }
            }
        </button>
    </div>  
</div>
