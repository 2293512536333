<div class='map-window'>
    <app-map-window [mode]='MWM.Point' [link]='null'></app-map-window>
</div>
<div class='content'
    [class.stage-card]='!!cardId'
    [class.multiple]='branches.length > 1'
    #content
>
    <ng-container *ngFor='let branch of branches'>
        <div class='branch'>
            <app-point-result-stack [cards]='branch.cards' [hiddenCards]='branch.hidden' [searchParams]='searchParams'></app-point-result-stack>    
            <app-branch-header *ngIf='branch.cards.length > 0' [card]='branch.cards[0]' [link]='headerLink' [cardId]='cardId' [pointId]='pointId'></app-branch-header>
        </div>
    </ng-container>
</div>
<div class='controls'>
    <a class='back' (activated)='goUp()' *ngIf='!landingPage' role='button' aria-label="חזרה לעמוד הקודם" clickOnReturn></a>
    <div class='menu-icon' *ngIf='landingPage'><app-menu-icon colorScheme='dark'></app-menu-icon></div>
    <a class='search' (activated)='searchSvc.search((searchParams && searchParams.original_query) || "")' aria-label="ביצוע חיפוש במערכת" 
        interactionEvent='mobile-search-button' [interactionEventWhere]='location.path(false)' [searchParams]='searchParams' clickOnReturn
    ></a>
</div>
<div class='quick-actions' [style.bottom]='"0px"' *ngIf='actionsCard'>
    <app-quick-actions [card]='actionsCard'></app-quick-actions>
</div>
<div class='back-to-search' [class.visible]='!cardId && !barePoint' #backToSearch (activated)='goUp()' clickOnReturn></div>
