<button class='open-modal' [class]='"state-" + state' (click)='activate.next()' aria-label='פתיחת אפשרויות הסינון'>
    <div class='img' *ngIf='state !== "inactive"'></div>
    <span>{{message}}</span>
    <!-- <div class='count' *ngIf='totalFilters'>{{totalFilters}}</div> -->
</button>
<div class='discovery' *ngIf='filtersState.showDiscovery && !filtersState.active' (click)='activate.next()' (mousedown)='$event.stopPropagation()' (touchstart)='$event.stopPropagation()'>
    <span class='text'>
        וואו, המון תוצאות… שווה אולי למקד את המפה או <strong>להפעיל סינון</strong>
    </span>
    <div class='arrow'></div>
    <div class='close' (click)='filtersState.showDiscovery = false; $event.stopPropagation()'></div>
</div>