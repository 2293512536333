<div class='container' [class.searching]='state.searching'>
    <div class='selector'
        [class.input]='state.searching_'
        [style.width]='selectorWidth + "px"'
        [style.right]='selectorRight + "px"'
    ></div>
    <button class='nation-wide' clickOnReturn #nationWide
            [class.with-text]='showButtonText()'
            [class.active]='isActive(true)'
            (activated)='selectNationWide()'
            [interactionEvent]='"geo_nation_wide"'
            [interactionEventWhere]='"geo-widget"'
    >בכל הארץ
        @if (state.searchState.nationalLoading()) {
            <span class='count count-loading' [class.active]='isActive(true)'></span>
        } @else {
            <span class='count'>{{state.searchState.nationWideCount()}}</span>
        }
    </button>
    <button class='map-region' clickOnReturn #mapRegion
            [class.with-text]='showButtonText()'
            [class.active]='isActive(false)'
            (activated)='selectMapRegion()'
            [interactionEvent]='"geo_map_area"'
            [interactionEventWhere]='"geo-widget"'
    >
        <span class='layout-desktop'>באיזור המפה</span>
        <span class='layout-mobile'>במפה</span>

        @if (state.searchState.mapLoading()) {
            <span class='count count-loading'></span>
        } @else {
            <span class='count' [class.active]='isActive(false)'>{{state.searchState.mapCount()}}</span>
        }
    </button>
    @if (state.searchState.onlyNational() && isActive(true)) {
        <span class='no-map-results'>(אין תוצאות על המפה)</span>
    } @else {
        <input class='area focusable' #area
            [class.active]='state.searching || !!state.area_'
            [placeholder]='state.inputPlaceholder | async'
            aria-label='חיפוש איזורים בארץ'
            (touchstart)='inputTouched($event)'
            (focus)='state.focusInput()'
            (blur)='state.blurInput()'
            (keydown)='$event.keyCode === 13 ? state.submitInput() : null'
            [(ngModel)]='state.query_'
            [interactionEvent]='"geo-search"'
            [interactionEventWhere]='"geo-widget"'
        />
        @if (!state.searching && !!state.area_) {
            @if (state.searchState.mapLoading()) {
                <span class='count input count-loading'>
                </span>    
            } @else {
                <span class='count input active'>
                    {{state.searchState.mapCount()}}
                </span>        
            }
        }
        <a class='clear' *ngIf='state.searching' (activated)='selectMapRegion()' clickOnReturn>
        </a>
    }
</div>