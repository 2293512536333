<app-menu-popup title='כל שירות' subtitle='כל השירותים החברתיים -<br/> לכל מצב, בכל מקום'>
    <ng-container class='content'>
        <div class='top'>
            <p>
                <strong>כל שירות</strong>&nbsp;ממפה מגוון של שירותים ומענים חברתיים שהמדינה, ארגונים וגופים  שונים מעמידים לרשותינו, האזרחים. בעזרת סינון, חיפוש ושיטוט במפה ניתן לגלות בדיוק מי יוכל לעזור לך.
            </p>
            <p>
                המערכת כרגע בגירסת בטא - חלק מהמידע יכול להיות חסר או לא מדויק.
            </p>
            <div class='link'>
                <span>אתר כל שירות מופעל ע״י</span>
                <a class='style-kz' href='https://www.kolzchut.org.il/he/כל-זכות:אודות_המיזם' target='_blank' aria-label='מעבר לאתר של כל זכות בטאב חדש'>כל זכות</a>
            </div>
            <div class='link'>
                <span>בסיוע</span>
                <a class='style-il' href='https://www.gov.il/he/departments/ministry_of_justice/govil-landing-page' target='_blank' aria-label='מעבר לאתר של משרד המשפטים בטאב חדש'>משרד המשפטים</a><br/>
                <span>ובסיוע</span>
                <a class='style-il' href='https://www.gov.il/he/departments/national-digital-agency/govil-landing-page' target='_blank' aria-label='מעבר לאתר של מערך הדיגיטל הלאומי בטאב חדש'>מערך הדיגיטל הלאומי</a><br/>
            </div>
            <p>אין בסיוע הממשלתי משום נטילת אחריות לפעילות האתר או תכניו. האתר מיועד לכלל המגדרים.</p>
            <p>
            כל זכות בע"מ (חל"צ), אינה אחראית לתוכן המוצג באתר. המידע באתר הוא כללי, ברובו מוזן בהדרגה ממקורות שונים בצורה אוטומטית, ואינו בהכרח מלא, מדויק, עדכני וכיו"ב.
            </p>
            <p>
                השימוש באתר הוא באחריות המשתמשים בלבד. למידע נוסף ראו הסבר על 
                <a href='https://www.kolzchut.org.il/he/כל-זכות:כל-שירות_-_מדיניות_השימוש' target='_blank'
                    aria-label='מדיניות האתר - נפתח בחלון חדש'>מדיניות האתר</a>.
            </p>
        </div>
    </ng-container>
</app-menu-popup>